import React from "react";
import { Link } from "react-router-dom";

function FormatWidget({
  title,
  subtitle,
  linkText,
  linkTo,
  children,
  abouttheProject = false,
  meetXR = false,
  newAndInsights = false,
  reviews = false,
}) {
  return (
    <div
      className={`lg:col-span-6 ${
        abouttheProject && "bg-gray-100 pt-4 pb-2 lg:pt-6"
      }  md:py-3 px-4  2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%]`}
      initial="hidden"
      animate="visible"
    >
      <div className="flex flex-col gap-1 ">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div className="flex flex-col mr-2 ">
            {!abouttheProject ? (
              <>
                {meetXR || newAndInsights || reviews ? (
                  <>
                    <h3 className="font-bold text-base md:text-lg">{title}</h3>
                    <h3 className="leading-5 text-xs md:text-sm font-extralight text-gray-500">
                      {subtitle}
                    </h3>
                  </>
                ) : (
                  <>
                    <h2 className="font-bold text-base md:text-lg">{title}</h2>
                    <h3 className="leading-5 text-xs md:text-sm font-extralight text-gray-500">
                      {subtitle}
                    </h3>
                  </>
                )}
              </>
            ) : (
              <h2 className="font-bold text-base md:text-lg">
                {title} - {subtitle}
              </h2>
            )}
          </div>
          {linkText && linkTo && (
            <div>
              <Link
                reloadDocument
                to={linkTo}
                className="p-1 mb-2 text-xs md:text-xs md:w-32 flex justify-center text-black border-black border font-extralight hover:bg-black hover:text-white"
              >
                {linkText}
              </Link>
            </div>
            // <div>
            //   <Link
            //     reloadDocument
            //     to={linkTo}
            //     className="px-4 py-1 mb-2 text-xs md:text-sm w-32 flex justify-center items-center text-black border border-black font-light rounded-md hover:bg-black hover:text-white transition-colors duration-300"
            //   >
            //     {linkText}
            //   </Link>
            // </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}

export default FormatWidget;
