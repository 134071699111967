import React, { useEffect, useState } from "react";
import { MdOutlineMailOutline } from "react-icons/md";
import { AiOutlinePhone } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import StarEmployeesImages from "./StarEmployeesImages";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { AGENT, BASE_URL } from "../api/constants";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import TwitterIcon from "../assets/twitter.png";
import InstagramIcon from "../assets/instagram.png";
import FacebookIcon from "../assets/facebook.png";
import LinkedInIcon from "../assets/linkedin.png";
import YouTubeIcon from "../assets/youtube.png";
import Projects from "./Projects";
import XploreDubaiNew from "./XploreDubaiNew";
import ProjectImages from "./ProjectImages";

const data = [
  {
    location: {
      coordinates: {
        lat: "",
        lng: "",
      },
      address: "",
      city: "",
      state: "",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "2029",
    },
    _id: "6720f371578603a844926393",
    property_name: "Damac Islands",
    property_name_slug: "damac-islands",
    price: "AED 2,250,000",
    developer: "Damac Properties",
    developer_name_slug: "damac-properties",
    type: [
      {
        name: "townhouses",
        bedrooms: "4 - 5",
      },
      {
        name: "villas",
        bedrooms: "6 - 7",
      },
    ],
    images: [
      {
        heading: "Your Own Island Gateway ",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1730212564/xr_media/xwqh3rufu2dpv5aeg3mi.jpg",
        description:
          "The development is conveniently situated between two key highways which are Emirates Road and Sheikh Mohammed Bin Zayed Road. Its location offers easy access to major transportation networks, which makes it highly connected to the rest of the city. Residents will benefit from having popular attractions such as parks, shopping centers, and entertainment venues nearby. Living in Damac Island offers a premium lifestyle that combines luxury with essential services. Public transportation is also available nearby, with buses and metro offering access to key parts of Dubai. Healthcare facilities are within reach, and families will find a variety of good schools in the surrounding areas. The development ensures a well-rounded lifestyle for its residents, with all the necessary facilities nearby.",
      },
      {
        heading: "Live in Paradise",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1730212654/xr_media/o71rpvm7hpvyqzlafgnz.jpg",
        description:
          "This is definitely going to be the best experience for the investors choosing Dubai. The Scope of the community is immense and truly in all sense it's just a paradise that spreads a heavenly feeling for the residents here. With the most impressive Designs inside and outside, this community is just adapting all the possibilities to make the community a nature-friendly one. This premium-packed villas and townhouses for sale in Dubai now stand as the best investment opportunity in Dubai. \n",
      },
    ],
    community_name: "Damac Islands",
    community_name_slug: "damac-islands",
    order: 1,
    id: "6720f371578603a844926393",
  },
  {
    location: {
      coordinates: {
        lat: "",
        lng: "",
      },
      address: "",
      city: "",
      state: "",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "Q1 2029",
    },
    _id: "671a3d9a0b7d7d831525ff6f",
    property_name: "Greenville",
    property_name_slug: "greenville-at-emaar-south",
    price: "AED 3,200,000",
    developer: "Emaar Properties",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "townhouses",
        bedrooms: "3 - 4",
      },
    ],
    images: [
      {
        heading: "A Community Where Families Flourish",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1729772680/xr_media/jot3amwgjorocki90t7b.jpg",
        description:
          "Beyond all the advantages, Greenville spreads a family-focused environment that encourages an active as well as positive lifestyle. The community features carefully curated spacious townhouses in Dubai that offer a perfect balance of privacy and opportunities to engage with the neighbors. Here the family connections grow stronger and you will have fun filled days ahead. Considering this as a reason, Greenville at Emaar South is the best ever investment choice that you can have in Dubai.  ",
      },
      {
        heading: "A Conveniently Connected Lifestyle",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1729772745/xr_media/arxgj4tafsebpdvjrfzw.jpg",
        description:
          "Offering a balance between calmness and convenience, this Emaar project is close to everything that your family needs. The location of Greenville close to the world’s largest airport which is Al Maktoum International airport, proximity to the busiest Middle East Port - Jebel Ali Port, Expo City Dubai and its seamless connectivity with central Dubai road arteries adds further value. Situated near a community park, mosque, and nursery, it’s easy to enjoy the essentials of family life without going far. No matter it's for work, school, or leisure, Greenville at Emaar South connects you to all the important places in your life.",
      },
    ],
    community_name: "EMAAR SOUTH",
    community_name_slug: "emaar-south",
    order: 2,
    id: "671a3d9a0b7d7d831525ff6f",
  },
  {
    location: {
      coordinates: {
        lat: "",
        lng: "",
      },
      address: "",
      city: "",
      state: "",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "671626e66e8189a7388b3f9d",
    property_name: "Marina Cove",
    property_name_slug: "marina-cove-at-dubai-marina",
    price: "AED 2,030,000",
    developer: "Emaar Properties",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "apartments",
        bedrooms: "1 - 4",
      },
    ],
    images: [
      {
        heading: "Dubai Marina’s Finest Location",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1729504318/xr_media/pnbhzb4vlmpp5ydjehqz.jpg",
        description:
          "The position of Marina Cove at Dubai Marina is in one of Dubai’s most desirable areas that offers great access to top attractions. Dubai Marina is located on Interchange 5 between Jebel Ali Port and the area that contains Dubai Internet City, and Dubai Media City. It’s also close to Jumeirah Lake Towers (JLT), Dubai Internet City, and Palm Jumeirah. Within the community Marina Cove is 2 minutes closer to Dubai Marina Mall and Pier 7, with a beautiful Marina Walk on your doorstep. Overall, of these factors, the connectivity is excellent here. Through the Metro, Tram, and Sheikh Zayed Road you are well-connected to the rest of Dubai. If the location is ideal your future returns are also going to be beyond excellent.",
      },
      {
        heading: "City Views and Vibrant Living",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1729504371/xr_media/g0oybsducreue6qywuwi.jpg",
        description:
          "In the Dubai real estate market, properties with scenic views command higher value. Here the panoramic views of Dubai Marina Skyline and calm water views of JLT from Marina Cove will drive the property’s value in future. This might be more beneficial at the time of renting apartments in Dubai Marina, especially the ones in Marina Cove. Other than the already mentioned views, the community offers mesmerizing marina views, sea views, Palm Jumeirah views, and the skyline views which will turn out to be an element that will drive these properties value immensely. The lively promenade, waterfront cafes, and vibrant social scene just steps away from the development ensure a lifestyle full of enjoyment and leisure is definitely an addon to the community’s appeal. \n",
      },
    ],
    community_name: "DUBAI MARINA",
    community_name_slug: "dubai-marina",
    order: 3,
    id: "671626e66e8189a7388b3f9d",
  },
  {
    location: {
      coordinates: {
        lat: "",
        lng: "",
      },
      address: "",
      city: "",
      state: "",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "670d0cc68f23ad8e44e2f811",
    property_name: "Bay Grove Residences",
    property_name_slug: "bay-grove-residences",
    price: "AED 1,850,000",
    developer: "Nakheel Properties ",
    developer_name_slug: "nakheel",
    type: [
      {
        name: "apartments",
        bedrooms: "1 - 3",
      },
      {
        name: "penthouse",
        bedrooms: "4",
      },
    ],
    images: [
      {
        heading: "Combining Convenience with elegance",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1728908362/xr_media/boraljm59wutxg3xemei.webp",
        description:
          "Explore the unique charm of Dubai Islands. This community is literally a natural haven with modern influences. Located just north of the city, Dubai Islands features more than 50 km of stunning waterfront homes, 20 km of beaches, and vast premium golf spaces. With the new access bridge and Infinity bridge, this coastal oasis remains closely connected to the city’s vibrant historic center. With its beautiful landscape filled with parks and amenities tailored to experiences for all age groups, Dubai Islands has a unique charm that keeps you in touch with nature. Invest in Dubai Island’s Bay Grove Residences for the highest level of rewards. ",
      },
      {
        heading: "Enjoy the Pleasure of Seaside Living",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1728908395/xr_media/y4ncxepiggjns9saydcp.webp",
        description:
          "At Island B you will discover dazzling sea views and pristine shores, all within the sight of the city’s lights. Being one of the nearest to the mainland, Island B is designed for various styles of beachfront living, from waterfront strolls to exhilarating watersports. Island B is closely connected to Dubai’s most iconic dining and entertainment locations. Holding the most premium Nakheel properties, this island is exclusive with the most sought after projects which clearly shows the strength of this location at Dubai Islands. ",
      },
    ],
    community_name: "Dubai Islands ",
    community_name_slug: "dubai-islands",
    order: 4,
    id: "670d0cc68f23ad8e44e2f811",
  },
  {
    location: {
      coordinates: {
        lat: "",
        lng: "",
      },
      address: "",
      city: "",
      state: "",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "670d04068f23ad8e44e2f434",
    property_name: "Velora 2",
    property_name_slug: "velora-2",
    price: "AED 2,930,000",
    developer: "Emaar Properties",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "townhouses",
        bedrooms: "3 & 4",
      },
    ],
    images: [
      {
        heading: "Every Day is an Adventure",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1728906033/xr_media/tg7en4l7m7khekaegij6.jpg",
        description:
          "At Velora 2 at The Valley Phase 2, you have 3 & 4 bedroom townhouses as an investment option. Coming to the looks and feel of the Velora 2, it is a definition of elegant and intuitive design inspired by nature, combining luxurious comfort, ample space, and architectural brilliance with a spread of optimism. This is a project bounded by rich greenery, modern amenities, and a lot of natural beauty that gives fresh air with a caress touch of wind. Together this gives each resident here a real sense of home amid nature. As an add on here the community provides a golden beach, kids dale, town center, sports village and much more to make you and your family feel relaxed. ",
      },
      {
        heading: "Modern Living in Nature's Embrace",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1728906058/xr_media/o1nsxi3i58gdblghcyhs.jpg",
        description:
          "Velora 2 in The Valley Phase 2 holds the most beautiful location by being between green spaces and top-notch amenities. What does the project offer actually? Offering all that makes your living convenient here, this project is just a haven of leisure, outdoor adventure, and scenic beauty. Beyond all of these features you can enjoy a wide range of recreational facilities such as nature inspired playscapes, community park and backyard sikkas, Velora 2 is ready to give you a nature inspired living in this beautiful community. ",
      },
    ],
    community_name: "THE VALLEY",
    community_name_slug: "the-valley",
    order: 5,
    id: "670d04068f23ad8e44e2f434",
  },
  {
    location: {
      coordinates: {
        lat: "25.10938970309102",
        lng: "55.24389178265418",
      },
      address: "dubai hills",
      city: "Dubai Hills",
      state: "Dubai",
      country: "United Arab Emirates",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66fd175ce8557f27652037d3",
    property_name: "Vida Residences",
    property_name_slug: "vida-residences",
    price: "AED 1,610,000",
    developer: "Emaar",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "apartments",
        bedrooms: "1-3",
      },
    ],
    images: [
      {
        heading: "Signature Style by Vida",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727861944/xr_media/vobsck9tvwztq5indhyp.jpg",
        description:
          "Since the Vida Residences Club Point has applied Vida’s signature combo of modernized luxury and great hospitality, you can experience a refreshing sense of energy and beauty in your day to day living. This branded apartment in Dubai will offer mind soothing activities from morning yoga sessions in the park to evening strolls along the tree-lined path. Being in this community will make the social connections flourish and promote personal well-being at the same time. ",
      },
      {
        heading: "Strategic Location within Dubai Hills",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727862003/xr_media/xp9th3jbm2ux8ouudrjd.jpg",
        description:
          "With all the essential amenities in the surrounding, Vida Residences Club Point has been planted in the right spot within this perfectly distributed community. With world-class health and education facilities, great level of leisure and wellness experiences, and the best retail and lifestyle destinations, the location of this project is truly a complete package. Altogether, the developer has made sure that all your needs are met within a short walk.",
      },
    ],
    community_name: "DUBAI HILLS ESTATE",
    community_name_slug: "dubai-hills-estate",
    order: 6,
    id: "66fd175ce8557f27652037d3",
  },
  {
    location: {
      coordinates: {
        lat: "25.258861109427983",
        lng: "55.279847481885206",
      },
      address: "",
      city: "",
      state: "",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66fba48fe8557f27652015a8",
    property_name: "Pier Point ",
    property_name_slug: "pier-point-at-rashid-yachts-and-marina",
    price: "1.93M AED",
    developer: "Emaar",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "apartments",
        bedrooms: "1 - 3",
      },
      {
        name: "townhouses",
        bedrooms: "3",
      },
    ],
    images: [
      {
        heading: "A Home That Tells a Story",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727767350/xr_media/zdtz3bzziambc5aezsr5.jpg",
        description:
          "In the heart of Rashid Yachts & Marina, Pier Point offers the best of both worlds—minutes from the historic charm of old Dubai and the vibrant pulse of Downtown. The thoughtfully designed surroundings, including the landscaped podium and communal spaces, create a sense of community and connection. Whether you're drawn to the city’s energy or the calm of the marina, Pier Point brings you closer to everything you love about Dubai.",
      },
      {
        heading: "Views That Captivate and Inspire",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727767388/xr_media/mk3u8ysiu96u8r0vbpcr.jpg",
        description:
          "From your home at Pier Point, every window frames a picture-perfect view. Whether it’s the serene waters of the marina, the lush, landscaped gardens below, or the dynamic skyline beyond, each scene captures the essence of this extraordinary location. Start your day with stunning sunrises and end it with the twinkling lights of the city—all from the comfort of your living room.",
      },
    ],
    community_name: "Rashid Yachts & Marina",
    community_name_slug: "mina-rashid",
    order: 7,
    id: "66fba48fe8557f27652015a8",
  },
  {
    location: {
      coordinates: {
        lat: "25.258890218553134",
        lng: "55.27976165119979",
      },
      address: "",
      city: "",
      state: "",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66fba16ae8557f27652014c2",
    property_name: "Porto View",
    property_name_slug: "porto-view-at-rashid-yachts-and-marina",
    price: "1.93M AED",
    developer: "Emaar",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "apartments",
        bedrooms: "1 - 3",
      },
      {
        name: "townhouses",
        bedrooms: "3",
      },
    ],
    images: [
      {
        heading: "A Timeless Address",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727766340/xr_media/qgvtscprthgdhketnsf1.jpg",
        description:
          "Just minutes from the heart of the city, this exclusive enclave offers residents a strategic position with easy access to both historical landmarks and modern amenities, fostering a sense of connection and vitality unlike any other. Whether you're savoring the charm of the old town or exploring the bustling energy of Downtown, you’re perfectly positioned to experience it all.",
      },
      {
        heading: "Views That Steal Your Breath Away",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727766366/xr_media/xizx3pp4ghqlrhp5gm7q.jpg",
        description:
          "At Porto View, every window frames a masterpiece, offering a daily dose of inspiration. Designed to maximize these captivating vistas, each residence offers unobstructed views of the marina, surrounded by lush greenery and the soothing ambiance of the waterfront. Watch the yachts glide by as the sun paints the sky in hues of gold, and let the gentle breeze remind you that this is where you belong.",
      },
    ],
    community_name: "Rashid Yachts & Marina",
    community_name_slug: "mina-rashid",
    order: 8,
    id: "66fba16ae8557f27652014c2",
  },
  {
    location: {
      coordinates: {
        lat: "24.985978046998568",
        lng: "55.39266679565201",
      },
      address: "Dubai",
      city: "Dubai",
      state: "Dubai",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66f3be9de8557f27651f809c",
    property_name: "Violet 4",
    property_name_slug: "violet-4-at-damac-hills-2",
    price: "1.96M AED",
    developer: "Damac",
    developer_name_slug: "damac-properties",
    type: [
      {
        name: "townhouses",
        bedrooms: "4",
      },
    ],
    images: [
      {
        heading: "Mesmerizing Architecture",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727285875/xr_media/v0yvfbpp2djd6zjmdsmu.png",
        description:
          "The Damac Violet 4 at Damac Hills 2 masterplan will feature 4 bedroom townhouses with modern interiors and spacious layouts. Hence, after the success of earlier violet phases, Damac will once again be seen as dedicated to presenting a marvel of architecture.",
      },
      {
        heading: "Extension of Ultimate Luxury",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727285922/xr_media/ujpupovcde4aumo3okkr.png",
        description:
          "Damac Violet 4 is the extension to ultimate luxury for people seeking top-notch residential options in Damac Hills 2. Investors and home buyers can look forward to more modern conveniences and architecturally pleasing residential units compared to phases 1 and 2.",
      },
    ],
    community_name: "DAMAC HILLS 2",
    community_name_slug: "damac-hills-2",
    order: 9,
    id: "66f3be9de8557f27651f809c",
  },
  {
    location: {
      coordinates: {
        lat: "25.263304726174592",
        lng: "55.27003049740704",
      },
      address: "Dubai",
      city: "Dubai",
      state: "Dubai",
      country: "Dubai",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66f432ede8557f27651f9133",
    property_name: "Saria by Beyond - Omniyat",
    property_name_slug: "saria-by-beyond-omniyat",
    price: "1.7M AED",
    developer: "Omniyat",
    developer_name_slug: "omniyat",
    type: [
      {
        name: "apartments",
        bedrooms: "1 - 3",
      },
      {
        name: "duplex-apartments",
        bedrooms: "2 - 4",
      },
    ],
    images: [
      {
        heading:
          "Architectural brilliance defines every corner of the tower, with its striking design extending down to the Marina promenade, offering panoramic views from every floor. Floor-to-ceiling windows, soaring 3.1 meters high, and wraparound balconies flood each residence with natural light, creating a canvas for breathtaking views. From the iconic Burj Khalifa to the serene sea and Marina, every home captures awe-inspiring vistas that shift with the natural beauty of the surroundings.",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727279617/xr_media/xxhamixtlpclzejx8d30.png",
        description: "Panoramic Masterpiece ",
      },
      {
        heading:
          "Inspired by tropical resorts, the property features lush landscapes, elegant interiors, and resort-style amenities designed for everyday indulgence. The grand lobby welcomes residents into a world of sophistication and serenity, reminiscent of an endless vacation. Whether relaxing in the infinity pool overlooking the Marina or enjoying the private rooftop terrace of a penthouse, this development ensures a perfect balance of tranquility and luxury.",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727279660/xr_media/cccmigv6goe3a17pntqs.png",
        description: "Resort Style Luxury",
      },
    ],
    community_name: "Saria by Beyond - Omniyat",
    community_name_slug: "saria-by-beyond-omniyat",
    order: 10,
    id: "66f432ede8557f27651f9133",
  },
  {
    location: {
      coordinates: {
        lat: "25.049540912029382",
        lng: "55.29601736811872",
      },
      address: "Dubai",
      city: "Dubai",
      state: "Dubai",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66f40aa9e8557f27651f8cb9",
    property_name: "The Acres Estate",
    property_name_slug: "the-acres-estate",
    price: "13.3M AED",
    developer: "Meraas",
    developer_name_slug: "meraas",
    type: [
      {
        name: "villas",
        bedrooms: "5 - 7",
      },
    ],
    images: [
      {
        heading:
          "Where your living space opens into a realm of natural grandeur and where venturing to greener pastures means returning home. That is The Acres Estates. A community distinguished by two\narchitectural designs: the Ivory Collection inviting the outside in, and the Amber Collection offering unparalleled exclusivity. Characterized by lofty ceilings, expansive interiors, and bespoke finishes, discover a residence where refined living is not only in the simple pleasures, but in the richness of nature.",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727269450/xr_media/jkycri9mo9vbtun4nomc.avif",
        description: "Exclusive Villas Surrounded by Gardens ",
      },
      {
        heading:
          "Placed within the Dubailand area, the community benefits from a well-connected road network with direct access to Sheikh Zayed Bin Hamdan Al Nahyan Street and Emirates Road. Just a 10-minute drive from the bustling Global Village and a mere 5 minutes from the esteemed Dubai Polo and Equestrian Club, and the Hamdan Sports Complex, The Acres is perfectly situated within an established area.",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727269601/xr_media/inzhdd7j0grgkjhiqpvf.jpg",
        description: "A Centrally Connected Luxury ",
      },
    ],
    community_name: "The Acres",
    community_name_slug: "the-acres",
    order: 11,
    id: "66f40aa9e8557f27651f8cb9",
  },
  {
    location: {
      coordinates: {
        lat: "25.01858443898733",
        lng: "55.29291693463163",
      },
      address: "Dubai",
      city: "Dubai",
      state: "Dubai",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66f3f0f2e8557f27651f8810",
    property_name: "Sun City",
    property_name_slug: "sun-city-by-damac",
    price: "2.27M AED",
    developer: "Damac",
    developer_name_slug: "damac-properties",
    type: [
      {
        name: "townhouses",
        bedrooms: "4 & 5",
      },
    ],
    images: [
      {
        heading: "Live on the Bright Side of Life",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727262661/xr_media/fwyzkcvj1godycfvvqoc.jpg",
        description:
          "Damac Sun City townhouses feature a contemporary design that seamlessly integrates with the lush landscapes, allowing residents to enjoy the beauty of nature without compromising on modern comforts. Each home is thoughtfully designed to provide ample living space, with high-quality finishes that elevate the overall experience. Integrating natural elements into the community’s design fosters a deeper connection with the outdoors, enhancing the feeling of tranquility.",
      },
      {
        heading: "Rejuvenates Your Mind & Soul",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1727262799/xr_media/kfoucbst4t5o5zkb4ioj.jpg",
        description:
          "Damac Sun City is located in the heart of Dubailand, a vibrant area known for its family-friendly atmosphere and proximity to major attractions. This prime location provides easy access to schools, shopping centers, and entertainment hubs. With its strategic location and blend of luxury and nature, Damac Sun City is set to become a sought-after destination for those seeking a balanced lifestyle in Dubai.",
      },
    ],
    community_name: "Sun City By Damac",
    community_name_slug: "sun-city-by-damac",
    order: 12,
    id: "66f3f0f2e8557f27651f8810",
  },
  {
    location: {
      coordinates: {
        lat: "24.867807107412684",
        lng: "55.15893618772579",
      },
      address: "",
      city: "",
      state: "",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66e98a14a4dc24a9e01be074",
    property_name: "Greenridge",
    property_name_slug: "greenridge-at-emaar-south",
    price: "AED 2,940,000",
    developer: "Emaar",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "mansions",
        bedrooms: "6 & 7",
      },
    ],
    images: [
      {
        heading: "Where Life Thrives\n",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1726580684/xr_media/jnzh9y6md9cuethe1gsc.png",
        description:
          "At the heart of Emaar South, Greenridge offers a warm and welcoming environment, specifically designed for families seeking a peaceful, active lifestyle. The community consists of beautifully crafted 3- and 4-bedroom townhouses, arranged to provide privacy while encouraging community interaction. Discover the joy of living in a space that enhances family bonds and inspires a life filled with fun and adventure.",
      },
      {
        heading: "A Connected Lifestyle\n",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1726580707/xr_media/up6xuhnhsw8j8gzcummj.png",
        description:
          "Beautifully positioned amidst green spaces and top-notch amenities, Greenridge emerges as a haven of leisure, education, and scenic beauty. With an array of recreational facilities, top-tier schools, and lush green expanses, this idyllic community stands as the epitome of vibrant, family-centric life in the heart of Emaar South.",
      },
    ],
    community_name: "EMAAR SOUTH",
    community_name_slug: "emaar-south",
    order: 14,
    id: "66e98a14a4dc24a9e01be074",
  },
  {
    location: {
      coordinates: {
        lat: "25.129436693764188",
        lng: "55.26148389454595",
      },
      address: "",
      city: "",
      state: "",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66e46626af358238a9b94267",
    property_name: "Golf Hillside",
    property_name_slug: "golf-hillside-at-dubai-hills-estate",
    price: "AED 1,470,000",
    developer: "Emaar",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "apartments",
        bedrooms: "1 - 3",
      },
    ],
    images: [
      {
        heading: "Close to Everything that Matters",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1726243825/xr_media/sokirbptmvjoluwf4ima.jpg",
        description:
          "A community can be called a ‘community’ only once it offers its residents all the social infrastructure within. For instance, people are looking for a neighborhood with world-class schools, quality healthcare, quick shopping trips, seamless transportation and much more. If this is what you are searching for, Golf Hillside at Dubai Hills is the best investment opportunity, because this community is known as a city within a city that offers everything you need within itself. As a plus point, here you are just a walk away from Dubai Hills Golf Club that offers relaxation and a great connection to nature.",
      },
      {
        heading: "Enjoy the Center Spot in Dubai",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1726243870/xr_media/eaxzw7y48clpfilgugi3.jpg",
        description:
          "This luxury apartment in Dubai is strategically positioned in the center of Dubai by ensuring you are relatively equidistant from all the major attractions of Dubai. Since the community lies along the Al Khail road, Downtown Dubai, and other major destinations are just minutes away from this spot. Not to mention, soon the Etihad Rail and Dubai Metro will offer all the residents here with a quick commute to the airport and emirate connections. ",
      },
    ],
    community_name: "DUBAI HILLS ESTATE",
    community_name_slug: "dubai-hills-estate",
    order: 15,
    id: "66e46626af358238a9b94267",
  },
  {
    location: {
      coordinates: {
        lat: "25.227716646764915",
        lng: "55.34213596528712",
      },
      address: "",
      city: "",
      state: "",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66e1bd85cee9db1b33751051",
    property_name: "LAVITA",
    property_name_slug: "lavita-at-the-oasis",
    price: "AED 36,000,000",
    developer: "Emaar",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "villas",
        bedrooms: "6",
      },
    ],
    images: [
      {
        heading: "The Perfect Setting",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1726068617/xr_media/dyt1folt44trvy7rdmw0.png",
        description:
          "Lavita at The Oasis enjoys an enviable location that seamlessly connects you to the vibrant energy of Dubai. Situated within close proximity to major transportation routes, including Sheikh Zayed bin Hamdan Road (D54) and the future extension of Al Khail Road (E44), this prime location ensures easy access to the city's bustling attractions while providing a tranquil escape from its fast-paced lifestyle. Whether you crave the excitement of urban life or seek solace in nature, Lavita at The Oasis offers the best of both worlds.",
      },
      {
        heading: "Your Secluded Sanctuary Awaits",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1726072541/xr_media/jb8xqqosgkde0awzczkc.png",
        description:
          "Lavita is a place where privacy meets connection, providing a peaceful retreat just moments from Dubai’s premier attractions. Whether you’re enjoying a round of golf, exploring luxury boutiques, or experiencing the cultural richness of Downtown Dubai, Lavita ensures you’re at the heart of it all, with a serene sanctuary to return to.",
      },
    ],
    community_name: "THE OASIS",
    community_name_slug: "the-oasis",
    order: 16,
    id: "66e1bd85cee9db1b33751051",
  },
  {
    location: {
      coordinates: {
        lat: "25.10618",
        lng: "55.24071",
      },
      address: "",
      city: "",
      state: "",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66d049f2897cb1c42f319cba",
    property_name: "Address Residences",
    property_name_slug: "address-residences-at-dubai-hills-estate",
    price: "AED 1,930,000",
    developer: "Emaar",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "apartments",
        bedrooms: "1 - 3",
      },
      {
        name: "townhouses",
        bedrooms: "3",
      },
    ],
    images: [
      {
        heading: "Connected Living",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1724926073/xr_media/obglv1dfkofgn3dyveas.jpg",
        description:
          "As Address Residences is situated in the lively community of Dubai Hills Estate, you are just seamlessly connected to the surrounding landmarks. Placement along the Al Khail road, walking distance to Dubai Hills Mall, Dubai Hills Park, top-tier healthcare and education are all the benefits that you can enjoy as a resident here. In total, everything you need is a stone’s throw away.",
      },
      {
        heading: "Transportation Benefits",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1724926086/xr_media/fgr0tnnqnnh8hxnymz2d.jpg",
        description:
          " In terms of transportation, the community will soon have an Integrated bus network. Beyond that, the upcoming great metro connectivity (with 3 metro stations, and 2 metro lines) and arrival of Etihad Rail will cut short traveling time to the airport and other emirate. These features are yet to come into existence, but still the community always ranks among the top 5 areas in Dubai every year since its launch.",
      },
    ],
    community_name: "DUBAI HILLS ESTATE",
    community_name_slug: "dubai-hills-estate",
    order: 17,
    id: "66d049f2897cb1c42f319cba",
  },
  {
    location: {
      coordinates: {
        lat: "25.20743018544326",
        lng: "55.35095532507339",
      },
      address: "Dubai Creek Harbour",
      city: "Dubai",
      state: "Dubai",
      country: "United Arab Emirates",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66c9dc96e07fc671698a9f52",
    property_name: "Address Residences",
    property_name_slug: "address-residences-at-dubai-creek-harbour",
    price: "AED 2,000,000",
    developer: "Emaar Properties",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "apartments",
        bedrooms: "1, 2 & 3",
      },
      {
        name: "townhouses",
        bedrooms: "3",
      },
    ],
    images: [
      {
        heading: "A Life of Refinement",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1724504522/xr_media/xvljhzg0gi11falvwgfj.png",
        description:
          "At Address Residences Dubai Creek Harbour, luxury feels effortless. Every day offers a retreat where the tranquility of the beach on Dubai Creek and the lush central park become your backdrop. This is where sophistication meets relaxation, allowing you to live a life of refined ease in the signature style of Address Hotels and Resorts.",
      },
      {
        heading: "Prime Positioning",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1724504762/xr_media/djqtvcdoqpbhxze7mkcr.png",
        description:
          "Residing at Address Residences Dubai Creek Harbour means having the metropolis at your fingertips. At the crossroads of Al Jaddaf and Festival City, the property offers unparalleled connectivity. With efficient road networks, four metro stations, and a water taxi to Downtown Dubai, every destination is just moments away. Dive into the heart of Dubai, effortlessly connected.",
      },
    ],
    community_name: "DUBAI CREEK HARBOUR",
    community_name_slug: "dubai-creek-harbour",
    order: 18,
    id: "66c9dc96e07fc671698a9f52",
  },
  {
    location: {
      coordinates: {
        lat: "24.86094663543911",
        lng: " 55.14402166817352",
      },
      address: "EMAAR SOUTH",
      city: "Dubai",
      state: "Dubai ",
      country: "United Arab Emirates",
    },
    features: {
      bathrooms: "0",
      area: "",
      year_built: "",
    },
    _id: "66ba4c24f171fc49fdabef5b",
    property_name: "Golf Point",
    property_name_slug: "golf-point-at-emaar-south",
    price: "AED 850,000",
    developer: "Emaar Properties",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "apartments",
        bedrooms: "1 - 3",
      },
    ],
    images: [
      {
        heading: "Prime Positioning",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1723661079/xr_media/scdcf35yeknwu6pot5tg.png",
        description:
          "Situated next to the 9th hole of an 18-hole championship golf course, Golf Point offers an unparalleled living experience. Enjoy the convenience of being close to the Golf Clubhouse and Driving Range, all within the vibrant and dynamic Emaar South community.",
      },
      {
        heading: "Where Every Corner Is a Destination",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1723661135/xr_media/hutn0jgqnmrooijrcbao.webp",
        description:
          "Perfectly positioned in the heart of Emaar South, Golf Point provides a harmonious balance between peaceful suburban living and the lively energy of the city. With easy highway access, the city's hustle is within reach, yet far enough that you can retreat to your peaceful haven whenever you desire.",
      },
    ],
    community_name: "EMAAR SOUTH",
    community_name_slug: "emaar-south",
    order: 19,
    id: "66ba4c24f171fc49fdabef5b",
  },
  {
    location: {
      coordinates: {
        lat: "25.01627437598942",
        lng: " 55.201947063537105",
      },
      address: "",
      city: "",
      state: "",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66e2da08cee9db1b33752dbd",
    property_name: "Palmiera 3",
    property_name_slug: "palmiera-3-at-the-oasis",
    price: "AED 9,180,000",
    developer: "Emaar",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "villas",
        bedrooms: "4",
      },
    ],
    images: [
      {
        heading: "The Perfect Setting",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1726137259/xr_media/sagjj3xmpmmyeuc426xa.jpg",
        description:
          "Palmiera 3 at The Oasis enjoys an enviable location that seamlessly connects you to the vibrant energy of Dubai. Situated within close proximity to major transportation routes, including Sheikh Zayed bin Hamdan Road (D54) and the future extension of Al Khail Road (E44), this prime location ensures easy access to the city's bustling attractions while providing a tranquil escape from its fast-paced lifestyle. Whether you crave the excitement of urban life or seek solace in nature, Palmiera at The Oasis offers the best of both worlds.",
      },
      {
        heading: "A Haven of Relaxation and Beauty",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1726137301/xr_media/r1wzfoibnsgekkm0rgxp.jpg",
        description:
          "Escape the hustle and bustle of the city and discover Palmiera 3 at The Oasis, a unique residential development that offers a tranquil oasis nestled amidst Dubai's vibrant landscape. With its exclusive collection of opulent villas, Palmiera 3 at The Oasis invites you to indulge in its luxurious tranquility where captivating natural surroundings merge seamlessly with exceptional design.",
      },
    ],
    community_name: "THE OASIS",
    community_name_slug: "the-oasis",
    order: 22,
    id: "66e2da08cee9db1b33752dbd",
  },
  {
    location: {
      coordinates: {
        lat: "25.00040145339469",
        lng: "55.42591105178712",
      },
      address: "",
      city: "",
      state: "",
      country: "",
    },
    features: {
      bathrooms: "",
      area: "",
      year_built: "",
    },
    _id: "66e42f9faf358238a9b93966",
    property_name: "Avena",
    property_name_slug: "avena-at-the-valley",
    price: "AED 4,360,000",
    developer: "Emaar",
    developer_name_slug: "emaar-properties",
    type: [
      {
        name: "villas",
        bedrooms: "4",
      },
    ],
    images: [
      {
        heading: "Embracing the Outdoors",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1726230055/xr_media/ddyg9bjfvtyel1irw6p2.jpg",
        description:
          "Avena is located in the heart of The Valley; it is your gateway to the tranquil central park and green spaces. Here, you can enjoy the best of both worlds—the serenity of nature and proximity to the city's prime destinations.",
      },
      {
        heading: "Perfectly Connected",
        url: "https://res.cloudinary.com/dkhns25jh/image/upload/v1726230081/xr_media/ffv3own0wayay2laqmo6.jpg",
        description:
          "With easy access to all major areas of Dubai and an array of green open spaces, The Valley is strategically located on the Dubai—Al Ain Road, a major connection point between the emirates of Dubai and Abu Dhabi.",
      },
    ],
    community_name: "THE VALLEY",
    community_name_slug: "the-valley",
    order: 26,
    id: "66e42f9faf358238a9b93966",
  },
];

function getYoutubeVideoId(url) {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?youtu(?:be\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.*[?&]v=)|\.be\/)([^&?/\s]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

// Example usage
const videoUrl = "https://youtu.be/GGehYW9hA-8?si=1UGChH1LZ4Rlryfd";
const videoId = getYoutubeVideoId(videoUrl);
// console.log(videoId); // Output: GGehYW9hA-8

function AgentDetailsNew({ agentInfo, imagesWithInfo }) {
  const { agentName } = useParams();

  const [agent, setAgent] = useState();
  const [moreOfTheTeam, setMoreOfTheTeam] = useState();

  const { pathname } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(AGENT + `${agentName}`);
        setAgent(response?.data?.agent);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [agentName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(BASE_URL);
        setMoreOfTheTeam(response?.data?.agent);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const buttons = [
    {
      icon: <AiOutlinePhone className="w-5 h-5" />,
      name: "PHONE",
      action: `tel:${agent?.phone}`,
    },
    {
      icon: <MdOutlineMailOutline className="w-5 h-5" />,
      name: "MAIL",
      action: `mailto:${agent?.email}`,
    },
    {
      icon: <FaWhatsapp className="w-5 h-5" />,
      name: "WHATSAPP",

      action: `https://wa.me/${agent?.phone}`,
    },
  ];

  return (
    <div className="bg-black text-white  overflow-hidden">
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        {/* <title>{agent?.name + "- Xperience Realty | Real Estate Dubai"} </title> */}
        <script type="application/ld+json">
          {JSON.stringify(agent?.schema_org?.properties)}
        </script>
      </Helmet>
      {/* <ScrollTriggerModal triggerPoints={[0.2, 0.5]}>
        <LetXpertsInput theme="light" />
      </ScrollTriggerModal> */}
      <div className="grid justify-items-center md:justify-items-start grid-cols-3 py-14 lg:py-28 px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%]">
        <div className="grid gap-8 col-span-3 lg:col-span-1 ">
          <div className=" md:flex-row  md:items-center justify-between mb-4 gap-4 ">
            <div className="flex flex-col mr-2 gap-6 ">
              <div className="w-[100vw] flex flex-col gap-4 lg:gap-2 ">
                <h1 className="font-bold text-3xl md:text-5xl lg:text-2xl text-center md:text-left ">
                  {agent?.name}
                </h1>
                <div className="flex flex-col gap-1 lg:gap-0">
                  {agent?.specialties && (
                    <p className="text-lg ">
                      <span className="font-semibold lg:text-base">
                        Specialization :{" "}
                      </span>
                      <span className="text-sm ">
                        {agent?.specialties?.join(", ")}
                      </span>
                    </p>
                  )}

                  {agent?.education && (
                    <p className="text-lg ">
                      <span className="font-semibold">Education : </span>
                      <span className="text-sm">{agent?.education}</span>
                    </p>
                  )}

                  {agent?.languages && (
                    <p className="text-lg ">
                      <span className="font-semibold lg:text-base">
                        Languages :{" "}
                      </span>
                      <span className="text-sm">
                        {agent?.languages?.join(", ")}
                      </span>
                    </p>
                  )}
                </div>
                {/* <p className="md:text-md lg:text-2xl text-center md:text-left">
                  {agent?.specialties}
                </p> */}
              </div>
              <div className="flex flex-col gap-4">
                <div className=" flex-col md:flex-row lg:flex-row gap-4 w-full hidden md:flex ">
                  {buttons.map((button, index) => (
                    <a
                      key={index}
                      href={button.action}
                      target={button.name === "WHATSAPP" ? "_blank" : "_self"}
                      rel="noopener noreferrer"
                      className=" bg-white p-3 px-12 lg:px-3 border  hover:bg-black text-black hover:text-white w-auto lg:text-sm xl:text-sm  2xl:text-lg  flex justify-center items-center gap-2"
                    >
                      <p>{button?.icon}</p>
                      <p className="font-semibold text-xs">{button?.name}</p>
                    </a>
                  ))}
                </div>

                {/* <div>

                  <ul className="flex md:justify-start text-white gap-4 items-center justify-center ">
                    {socialMediaLinks.map(({ icon: Icon, href }, index) => (
                      <a
                        key={index}
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <li>
                          <button
                            className={`border  p-2 bg-white text-black hover:bg-black hover:text-white`}
                          >
                            <Icon className="h-5 w-5" />
                          </button>
                        </li>
                      </a>
                    ))}
                  </ul>
                </div> */}

                <div>
                  <ul className="flex md:justify-start  text-white gap-2 items-center justify-center">
                    <li>
                      <a href={agent?.social_links?.youtube}>
                        <img
                          src={YouTubeIcon}
                          className="w-[15vw] md:w-[5vw] lg:w-10"
                          target="_blank"
                          rel="noopener noreferrer"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href={agent?.social_links?.facebook}>
                        <img
                          src={FacebookIcon}
                          className="w-[15vw] md:w-[5vw] lg:w-10"
                          target="_blank"
                          rel="noopener noreferrer"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href={agent?.social_links?.linkedin}>
                        <img
                          src={LinkedInIcon}
                          className="w-[15vw] md:w-[5vw] lg:w-10"
                          target="_blank"
                          rel="noopener noreferrer"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href={agent?.social_links?.instagram}>
                        <img
                          src={InstagramIcon}
                          className="w-[15vw] md:w-[5vw] lg:w-10"
                          target="_blank"
                          rel="noopener noreferrer"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href={agent?.social_links?.twitter}>
                        <img
                          src={TwitterIcon}
                          className="w-[15vw] md:w-[5vw] lg:w-10"
                          target="_blank"
                          rel="noopener noreferrer"
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2  pt-10  px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] bg-white gap-10">
        <div className="grid col-span-2 grid-cols-3 gap-8 lg:w-1/2">
          <div className=" text-black col-span-3 md:col-span-2 lg:col-span-3 ">
            {agent?.experience > 0 && (
              <p className="mb-2 text:lg lg:text-xl ">
                <span className="font-bold">Experience: </span>
                <span className="text-base lg:text-lg">
                  {agent?.experience}
                </span>
              </p>
            )}

            {agent?.education && (
              <p className="mb-2 text:lg lg:text-xl ">
                <span className="font-bold">Education : </span>
                <span className="text-base lg:text-lg">{agent?.education}</span>
              </p>
            )}

            {agent?.bio && (
              <p className="mb-2 text:lg lg:text-xl  ">
                <span className="font-bold">Professional Snapshot : </span>
                <span className="text-base lg:text-lg">{agent?.bio}</span>
              </p>
            )}

            {agent?.personal_info && (
              <p className="mb-2 text:lg lg:text-xl  ">
                <span className="font-bold">Personal Information : </span>
                <span className="text-base lg:text-lg">
                  {agent?.personal_info}
                </span>
              </p>
            )}
            <div className="flex flex-col md:flex-row lg:flex-row gap-4 w-full md:hidden ">
              {buttons.map((button, index) => (
                <a
                  key={index}
                  href={button.action}
                  target={button.name === "WHATSAPP" ? "_blank" : "_self"}
                  rel="noopener noreferrer"
                  className="w-full  p-3 border border-black lg:text-sm xl:text-sm 2xl:text-sm  lg:w-full flex justify-center items-center gap-2 hover:bg-black hover:text-white"
                >
                  {button.icon}
                  <p className="font-semibold hover:bg-black hover:text-white">
                    {button.name}
                  </p>
                </a>
              ))}
            </div>
          </div>

          <div className="object-cover object-top lg:absolute  lg:top-[30%] xl:top-[30%]  2xl:top-[25%]  lg:right-20 xl:right-[6%] 2xl:right-[12%] flex items-center justify-center w-full lg:w-[30%] 2xl:w-[15%] col-span-3 md:col-span-1 order-first md:order-last">
            <img src={agent?.profile_picture} alt={agent?.name} />
          </div>
        </div>
      </div>

      {/* Divider */}
      <div className="py-10 bg-white"></div>
      {/* Divider End */}

      <div className=" px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] bg-white text-black flex flex-col">
        <span className="text-base text-gray-400">Dubai</span>
        <h2 className="font-bold">Latest Projects!</h2>
        <ProjectImages imagesWithInfo={data} />
      </div>

      {/* Divider */}
      <div className="py-10 bg-white"></div>
      {/* Divider End */}

      <div className="px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] bg-white text-black flex flex-col">
        <span className="text-base text-gray-400">Stay on the Loop</span>
        <h2 className="font-bold">Watch My Latest Videos</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 col-span-2 gap-5 mt-3">
          {agent?.video_links?.map((url) => {
            return (
              url && (
                <div class="max-w-sm rounded overflow-hidden shadow-lg relative group">
                  <img
                    src={`https://img.youtube.com/vi/${getYoutubeVideoId(
                      url
                    )}/maxresdefault.jpg`}
                    alt="YouTube Thumbnail"
                    class="w-full"
                  />

                  <a
                    href={url}
                    target="_blank"
                    class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                  >
                    <div class="bg-white rounded-full p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-8 w-8 text-red-600"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M3 22v-20l18 10-18 10z" />
                      </svg>
                    </div>
                  </a>
                </div>
              )
            );
          })}
        </div>
      </div>
      {/* Divider */}
      <div className="py-10 bg-white"></div>
      {/* Divider End */}
      <div className="bg-white text-black">
        <div class="max-w-4xl mx-auto p-8  ">
          <h2 class="text-2xl font-semibold text-center mb-8">
            Most Common FAQ
          </h2>

          <div class="space-y-6">
            <div class="p-4 bg-gray-100 rounded-lg shadow-md">
              <h3 class="text-lg font-medium text-gray-800">
                What is the return policy?
              </h3>
              <p class="mt-2 text-gray-600">
                Our return policy allows you to return items within 30 days of
                purchase for a full refund.
              </p>
            </div>

            <div class="p-4 bg-gray-100 rounded-lg shadow-md">
              <h3 class="text-lg font-medium text-gray-800">
                How long does shipping take?
              </h3>
              <p class="mt-2 text-gray-600">
                Shipping typically takes 5-7 business days, depending on your
                location.
              </p>
            </div>

            <div class="p-4 bg-gray-100 rounded-lg shadow-md">
              <h3 class="text-lg font-medium text-gray-800">
                Can I change my order after placing it?
              </h3>
              <p class="mt-2 text-gray-600">
                Yes, you can change your order within 24 hours of placing it by
                contacting our support team.
              </p>
            </div>

            <div class="p-4 bg-gray-100 rounded-lg shadow-md">
              <h3 class="text-lg font-medium text-gray-800">
                Do you ship internationally?
              </h3>
              <p class="mt-2 text-gray-600">
                Yes, we ship to select international locations. Shipping rates
                and times may vary.
              </p>
            </div>

            <div class="p-4 bg-gray-100 rounded-lg shadow-md">
              <h3 class="text-lg font-medium text-gray-800">
                What payment methods are accepted?
              </h3>
              <p class="mt-2 text-gray-600">
                We accept credit cards, PayPal, and other major payment options
                for your convenience.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className=" px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] bg-white text-black gap-10 flex flex-col py-10">
        <h2 className="font-bold text-2xl md:text-3xl lg:text-4xl ">
          More of the team
        </h2>
        <StarEmployeesImages data={moreOfTheTeam} />
      </div> */}
    </div>
  );
}

export default AgentDetailsNew;
