import React, { useEffect, useState } from "react";
import FullReviewModal from "./FullReviewModal";
import ReviewPopup from "./ReviewPopup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { ALL_REVIEWS } from "../api/constants";
import { IoPersonOutline } from "react-icons/io5";
import { RiStarSFill } from "react-icons/ri";

function Reviews({ viewAll = false }) {
  const [isReviewPopupOpen, setReviewPopupOpen] = useState(false);
  const [isFullReviewModalOpen, setFullReviewModalOpen] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(ALL_REVIEWS);
        const visibleReviews = response?.data?.reviews.filter(
          (review) => review?.showReview
        );
        setReviews(visibleReviews);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleReadMoreClick = (review) => {
    setSelectedReview(review);
    setFullReviewModalOpen(true);
  };

  const handleReviewSubmitSuccess = (message) => {
    toast.success(message);
  };

  const handleReviewSubmitError = (message) => {
    toast.error(message);
  };
  return (
    <div className="pb-8 md:pb-3 md:mt-2 lg:pb-3 ">
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 gap-4 md:gap-8 lg:gap-2">
        {reviews?.slice(0, viewAll ? reviews.length : 4)?.map((review) => (
          // <blockquote
          //   key={review?._id}
          //   className="border border-gray-300 p-2 lg:p-4 2xl:p-6 shadow-sm sm:p-8 h-auto md:h-auto lg:h-auto 3xl:h-auto overflow-hidden relative flex flex-col "
          // >
          //   <div className="flex items-center gap-4">
          //     {review?.imageUrl ? (
          //       <img
          //         src={review?.imageUrl}
          //         alt="Profile"
          //         className="w-9 h-9 object-cover "
          //       />
          //     ) : (
          //       <IoPersonOutline className="w-9 h-9 border-2 border-black p-1" />
          //     )}
          //     <div>
          //       <div className="flex justify-start text-xs">
          //         {[...Array(review?.numberOfStars)].map((_, i) => (
          //           <RiStarSFill key={i} />
          //           // <img key={i} src={star} alt="star"  className="h-5 w-5" />
          //         ))}
          //       </div>
          //       <p className="leading-5 line-clamp-1 text-xs md:text-sm lg:text-sm font-medium text-gray-900">
          //         {review?.name?.toUpperCase()}
          //       </p>
          //     </div>
          //   </div>

          //   <div className="mt-2 text-justify line-clamp-4 text-xs md:text-sm lg:text-sm text-gray-700 overflow-hidden">
          //     {review?.message}
          //   </div>

          //   {review?.message?.length > 100 && (
          //     <div className="mt-2 text-sm lg:text-base">
          //       <button
          //         className="text-gray-400 hover:text-black "
          //         onClick={() => handleReadMoreClick(review)}
          //       >
          //         Read More
          //       </button>
          //     </div>
          //   )}
          // </blockquote>

          <blockquote
            key={review?._id}
            className="border border-gray-300 p-4 lg:p-6 shadow-lg rounded-lg transition-transform hover:-translate-y-1 hover:shadow-xl overflow-hidden relative flex flex-col"
          >
            <div className="flex items-center gap-4">
              {review?.imageUrl ? (
                <img
                  src={review?.imageUrl}
                  alt="Profile"
                  className="w-10 h-10 rounded-full object-cover border border-gray-300"
                />
              ) : (
                <IoPersonOutline className="w-10 h-10 border-2 border-gray-300 p-1 rounded-full text-gray-500" />
              )}
              <div>
                <div className="flex justify-start  text-xs">
                  {[...Array(review?.numberOfStars)].map((_, i) => (
                    <RiStarSFill key={i} />
                  ))}
                </div>
                <p className="leading-5 text-sm font-semibold text-gray-900 line-clamp-1">
                  {review?.name?.toUpperCase()}
                </p>
              </div>
            </div>

            <div className="mt-3 text-justify line-clamp-4 text-sm text-gray-700">
              {review?.message}
            </div>

            {review?.message?.length > 100 && (
              <div className="mt-3 text-right">
                <button
                  className="text-gray-500 hover:text-black text-sm font-medium transition-colors duration-200"
                  onClick={() => handleReadMoreClick(review)}
                >
                  Read More
                </button>
              </div>
            )}
          </blockquote>
        ))}
      </div>

      {/* Leave Your Review Button */}
      <div className="mt-8 flex justify-center text-sm lg:text-base">
        <button
          className="bg-black text-xs text-white px-4 py-2 hover:bg-gray-800"
          onClick={() => setReviewPopupOpen(true)}
        >
          LEAVE A REVIEW
        </button>
      </div>

      {/* Full Review Modal */}
      <FullReviewModal
        isOpen={isFullReviewModalOpen}
        onClose={() => setFullReviewModalOpen(false)}
        review={selectedReview}
      />

      {/* Review Submission Popup */}
      <ReviewPopup
        isOpen={isReviewPopupOpen}
        onClose={() => setReviewPopupOpen(false)}
        onSuccess={handleReviewSubmitSuccess}
        onError={handleReviewSubmitError}
      />

      {/* <ToastContainer /> */}
    </div>
  );
}

export default Reviews;
