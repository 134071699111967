import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FETCH_All_DEVELOPERS } from "../api/constants";
import { Helmet } from "react-helmet";

function AllDevelopers() {
  //     "https://res.cloudinary.com/dkhns25jh/image/upload/v1722445593/xr_media/vfnqx9zkc7tio5mjfubv.jpg",

  const [developers, setAllDevelopers] = useState([]);

  const { pathname } = useLocation();

  const imageUrls = [
    "https://res.cloudinary.com/dkhns25jh/image/upload/v1722858422/xr_media/hukbynue5yh3nq3oy7se.jpg",
    "https://res.cloudinary.com/dkhns25jh/image/upload/v1722445624/xr_media/bdgd6g378gffktf6hzfd.jpg",
    "https://res.cloudinary.com/dkhns25jh/image/upload/v1722445533/xr_media/jgv4q4oixikn7hytciv4.jpg",
    "https://res.cloudinary.com/dkhns25jh/image/upload/v1722445696/xr_media/sdv6w1ppiaxssfwfm2wr.jpg",
    "https://res.cloudinary.com/dkhns25jh/image/upload/v1722445725/xr_media/fixj1qygzcoxlcohwp0u.jpg",
    "https://res.cloudinary.com/dkhns25jh/image/upload/v1722445770/xr_media/dv2zlbdy0g9ofavfdpt7.png",
    // "https://res.cloudinary.com/dkhns25jh/image/upload/w_1280,f_auto,q_auto/v1727270320/xr_media/lgy01b4atrfm0kv11otw.png",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(FETCH_All_DEVELOPERS);
        const developersData = response.data.data;

        setAllDevelopers(developersData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const slides = developers.map((developer, index) => (
    <div key={index} className="flex justify-center">
      <Link reloadDocument to={`/developer/${developer.developer_slug}/`}>
        <img
          src={developer.logo_img_url}
          alt={`Slide ${index + 1}`}
          className="w-28 md:w-36"
        />
      </Link>
    </div>
  ));

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>

      <div className="pt-10 px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%]">
        <div className="flex flex-col mb-4 ">
          <h1 className="text-xl font-normal">OUR SIGNATURE PARTNERS</h1>
          <p className="font-light mt-4">
            Xperience Realty proudly collaborates with the most reputable real
            estate developers in Dubai, ensuring you have access to the finest
            residential opportunities across the city. Each development partner
            upholds the highest standard in construction, design, and
            sustainability, allowing us to offer properties that sync with the
            aspirations and lifestyle of Dubai’s dynamic market. With us, you
            can invest with confidence knowing that you’re choosing properties
            delivered by the best developers in the Industry.
          </p>
        </div>
        <div className="py-8 items-center  flex flex-wrap gap-4 md:flex md:flex-row ">
          {slides}
        </div>
      </div>
    </>
  );
}

export default AllDevelopers;
