import React from "react";
import FormatWidget from "./FormatWidget";
import Reviews from "./Reviews";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function ReviewsComponent({ viewAll }) {
  // Destructure viewAll from props
  const { pathname } = useLocation();
  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>
      <div>
        <FormatWidget
          title="REVIEWS"
          subtitle="Read trusted reviews from our customers."
          linkText={!viewAll ? "VIEW ALL" : null} // Show "VIEW ALL" only if not viewing all
          linkTo={!viewAll ? "/customer-reviews/" : null} // Link to /reviews only if not viewing all
          reviews={true}
        >
          <Reviews viewAll={viewAll} />
        </FormatWidget>
      </div>
    </>
  );
}

export default ReviewsComponent;
