import React, { useState, useEffect } from "react";
import ProjectImages from "./ProjectImages";
import { Link } from "react-router-dom";
import FormatWidget from "./FormatWidget";

function XploreDubaiNew({ propertyTypes }) {
  const [activeFilter, setActiveFilter] = useState("");
  const [filteredProperties, setFilteredProperties] = useState(propertyTypes);

  // Filter types configuration
  const filters = [
    { id: "apartment", label: "Apartments" },
    { id: "villa", label: "Villas" },
    { id: "townhouse", label: "Townhouses" },
    // { id: "all", label: "View All" },
  ];

  // Function to filter properties based on type
  const filterProperties = (properties, filterType) => {
    if (filterType === "all") {
      return properties;
    }

    // Convert filter type to match possible variations in the data
    const filterVariations = [
      filterType,
      filterType + "s", // plural form
      filterType.toUpperCase(),
      filterType.toUpperCase() + "S",
      filterType.toLowerCase(),
      filterType.toLowerCase() + "s",
    ];

    return properties?.filter((property) => {
      // Check if any of the property types match our filter variations
      return property.type.some((typeObj) =>
        filterVariations.includes(typeObj.name.toLowerCase())
      );
    });
  };

  // Handle filter click
  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    const filtered = filterProperties(propertyTypes, filter);
    setFilteredProperties(filtered);
  };

  // Effect to update filtered properties when prop changes
  useEffect(() => {
    setFilteredProperties(propertyTypes);
  }, [propertyTypes]);

  return (
    <FormatWidget>
      <div className="mb-2 md:mb-4">
        <h2 className="font-bold text-base md:text-lg md:mb-2 mb-4">
          XPLORE DUBAI WITH XR
        </h2>
        <div className="flex flex-col gap-4 md:flex-row md:gap-0 md:justify-between">
          <div className="flex flex-wrap gap-2 justify-start">
            {filters?.map((filter) => (
              <div
                key={filter.id}
                onClick={() => handleFilterClick(filter.id)}
                className={`px-2 py-1 text-center text-xs md:text-xs flex justify-center text-black border-black border font-extralight hover:bg-black hover:text-white cursor-pointer
                    ${
                      activeFilter === filter.id
                        ? "bg-black text-white border-black"
                        : "bg-white text-black border-black hover:bg-black hover:text-white"
                    }`}
              >
                {filter.label.toUpperCase()}
              </div>
            ))}
          </div>

          <Link
            reloadDocument
            to={"/dubai-properties/"}
            className="p-1 text-xs sm:text-sm md:text-xs md:w-32 flex justify-center text-black border-black border font-extralight hover:bg-black hover:text-white cursor-pointer"
          >
            VIEW ALL
          </Link>
        </div>
      </div>
      {/* ProjectImages component */}
      {filteredProperties?.length > 0 ? (
        <ProjectImages imagesWithInfo={filteredProperties} />
      ) : (
        <div className="text-center py-8 text-gray-500">
          No properties found for the selected type.
        </div>
      )}
    </FormatWidget>
  );
}

export default XploreDubaiNew;
