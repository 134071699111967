// // /* eslint-disable jsx-a11y/anchor-is-valid */
// // import React, { useState } from "react";
// // import axios from "axios";
// // import { toast } from "react-toastify";
// // import "react-toastify/dist/ReactToastify.css";
// // import { CONTACT_US } from "../api/constants";
// // import { useLocation } from "react-router-dom";

// // function LetXpertsInput({ theme, onFormSubmit }) {
// //   const { pathname } = useLocation();

// //   const initialFormData = {
// //     firstname: "",
// //     lastname: "",
// //     email: "",
// //     phone: "",
// //     pageUrl: "",
// //   };

// //     console.log(theme);

// //   const [formData, setFormData] = useState(initialFormData);

// //   const handleChange = (e) => {
// //     setFormData({
// //       ...formData,
// //       [e.target.id]: e.target.value,
// //     });
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();

// //     try {
// //       formData.pageUrl = `https://www.xrealty.ae${pathname}`;
// //       const response = await axios.post(CONTACT_US, formData)

// //       // Handle success based on status code
// //       if (response.status === 201) {
// //         toast.success(response.data.message || "Form submitted successfully!");
// //         setFormData(initialFormData);
// //         localStorage.setItem("formSubmitted", "true");
// //       }
// //     } catch (error) {

// //       // Check if the error is due to validation or server issues
// //       if (error.response && error.response.status === 400) {
// //         toast.error(error.response.data.error || "Validation error. Please check your input.");
// //       } else {
// //         toast.error("An error occurred during form submission.");
// //       }
// //     }
// //   };

// //   return (
// //     <>
// //         <h1
// //           className={` text-base md:text-xl text-center mb-4 ${
// //             theme === "light" ? " text-black" : "text-white"
// //           }`}
// //         >
// //           CONNECT WITH OUR EXPERTS
// //         </h1>
// //       <div className="flex flex-col justify-center items-center w-full lg:gap-6 py-12 md:py-14 lg:py-0">
// //         <form
// //           onSubmit={handleSubmit}
// //           className="w-full grid grid-cols-6 gap-6 pt-5 lg:pt-0"
// //         >
// //           <div className="col-span-6 sm:col-span-3 ">
// //             <input
// //               type="text"
// //               id="firstname"
// //               name="first_name"
// //               placeholder="First Name"
// //               className={`font-thin mt-1 w-full py-3 text-xs   md:text-sm  shadow-sm border-b focus:outline-none ${
// //                 theme === "light"
// //                   ? "bg-white border-black text-black"
// //                   : "bg-black text-gray-400 border-gray-400"
// //               }`}
// //               value={formData.firstname}
// //               onChange={handleChange}
// //             />
// //           </div>

// //           <div className="col-span-6 sm:col-span-3">
// //             <input
// //               type="text"
// //               id="lastname"
// //               name="last_name"
// //               placeholder="Last Name"
// //               className={`font-thin mt-1 w-full py-3 text-xs  md:text-sm  shadow-sm border-b focus:outline-none ${
// //                 theme === "light"
// //                   ? "bg-white border-black text-black"
// //                   : "bg-black text-gray-400 border-gray-400"
// //               }`}
// //               value={formData.lastname}
// //               onChange={handleChange}
// //             />
// //           </div>

// //           <div className="col-span-6">
// //             <input
// //               type="email"
// //               id="email"
// //               name="email"
// //               placeholder="Email Address"
// //               className={`font-thin mt-1 w-full py-3 text-xs  md:text-sm   shadow-sm border-b focus:outline-none ${
// //                 theme === "light"
// //                   ? "bg-white border-black text-black"
// //                   : "bg-black text-gray-400 border-gray-400"
// //               }`}
// //               value={formData.email}
// //               onChange={handleChange}
// //             />
// //           </div>

// //           <div className="col-span-6 sm:col-span-6">
// //             <input
// //               type="tel"
// //               id="phone"
// //               name="phone"
// //               placeholder="Phone Number"
// //               className={`font-thin mt-1  w-full py-3 text-xs md:text-sm  border-b focus:outline-none ${
// //                 theme === "light"
// //                   ? "bg-white border-black text-black"
// //                   : "bg-black text-gray-400 border-gray-400"
// //               }`}
// //               value={formData.phone}
// //               onChange={handleChange}
// //             />
// //           </div>

// //           <div className="col-span-6 sm:flex sm:items-center gap-4">
// //             <button
// //               type="submit"
// //               className="inline-block shrink-0 w-full border border-white bg-black py-3 md:py-4 lg:px-12 lg:py-4 text-xs md:text-sm  font-thin text-white transition hover:border hover:bg-white  hover:border-black  focus:outline-none hover:text-black tracking-[0.2rem]"
// //             >
// //               REGISTER YOUR INTEREST
// //             </button>
// //           </div>
// //         </form>
// //         <div className="w-full text-center font-thin">
// //           <p
// //             className={`text-xs  md:text-sm   shadow-sm ${
// //               theme === "light" ? " text-black" : " text-gray-400"
// //             }`}
// //           >
// //             By clicking Submit, you agree to our{" "}
// //             <a
// //               href="https://www.xrealty.ae/privacy-policy/"
// //               className={`underline font-bold ${
// //                 theme === "light" ? " text-black" : " text-gray-400"
// //               } `}
// //             >
// //               Terms and Conditions
// //             </a>{" "}
// //             and{" "}
// //             <a
// //               href="https://www.xrealty.ae/privacy-policy/"
// //               className={`underline font-bold ${
// //                 theme === "light" ? " text-black" : " text-gray-400"
// //               } `}
// //             >
// //               Privacy Policy
// //             </a>
// //             .
// //           </p>
// //         </div>
// //         {/* <ToastContainer /> */}
// //       </div>
// //     </>
// //   );
// // }

// // export default LetXpertsInput;

// /* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { useState } from "react";
// import axios from "axios";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { CONTACT_US } from "../api/constants";
// import { useLocation } from "react-router-dom";

// function LetXpertsInput({ theme, onFormSubmit, registerInterest,property }) {
//   const { pathname } = useLocation();

//   const initialFormData = {
//     firstname: "",
//     lastname: "",
//     email: "",
//     phone: "",
//     pageUrl: "",
//   };

//   const [formData, setFormData] = useState(initialFormData);

//   const handleChange = (e) => {
//     const { id, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [id]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       formData.pageUrl = `https://www.xrealty.ae${pathname}`;
//       const response = await axios.post(CONTACT_US, formData);

//       if (response.status === 201) {
//         toast.success(response.data.message || "Form submitted successfully!");
//         setFormData(initialFormData);
//         localStorage.setItem("formSubmitted", "true");
//         if (onFormSubmit) onFormSubmit();
//       }
//     } catch (error) {
//       const errorMessage =
//         error.response?.status === 400
//           ? error.response.data.error ||
//             "Validation error. Please check your input."
//           : "An error occurred during form submission.";
//       toast.error(errorMessage);
//     }
//   };
//   console.log(registerInterest);
//   return (
//     <div
//       className={`flex flex-col items-center w-full ${
//         registerInterest === true ? "py-10 md:py-14 lg:py-0" : "py-4 md:py-14 lg:py-0"
//       }`}
//     >
//       <h1
//         className={`text-base md:text-xl text-center mb-4 ${
//           theme === "light" ? "text-black" : "text-white"
//         } ${property ? "hidden": "block"}`}
//       >
//         CONNECT WITH OUR EXPERTS
//       </h1>
//       <form
//         onSubmit={handleSubmit}
//         className="w-full grid grid-cols-6 gap-6 pt-5 lg:pt-0"
//       >
//         <div className="col-span-6 sm:col-span-3">
//           <input
//             type="text"
//             id="firstname"
//             name="firstname"
//             placeholder="First Name"
//             className={`font-thin mt-1 w-full py-3 text-xs md:text-sm shadow-sm border-b focus:outline-none ${
//               theme === "light"
//                 ? "bg-white border-black text-black"
//                 : "bg-black text-gray-400 border-gray-400"
//             }`}
//             value={formData.firstname}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="col-span-6 sm:col-span-3">
//           <input
//             type="text"
//             id="lastname"
//             name="lastname"
//             placeholder="Last Name"
//             className={`font-thin mt-1 w-full py-3 text-xs md:text-sm shadow-sm border-b focus:outline-none ${
//               theme === "light"
//                 ? "bg-white border-black text-black"
//                 : "bg-black text-gray-400 border-gray-400"
//             }`}
//             value={formData.lastname}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="col-span-6">
//           <input
//             type="email"
//             id="email"
//             name="email"
//             placeholder="Email Address"
//             className={`font-thin mt-1 w-full py-3 text-xs md:text-sm shadow-sm border-b focus:outline-none ${
//               theme === "light"
//                 ? "bg-white border-black text-black"
//                 : "bg-black text-gray-400 border-gray-400"
//             }`}
//             value={formData.email}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="col-span-6">
//           <input
//             type="tel"
//             id="phone"
//             name="phone"
//             placeholder="Phone Number"
//             className={`font-thin mt-1 w-full py-3 text-xs md:text-sm border-b focus:outline-none ${
//               theme === "light"
//                 ? "bg-white border-black text-black"
//                 : "bg-black text-gray-400 border-gray-400"
//             }`}
//             value={formData.phone}
//             onChange={handleChange}
//           />
//         </div>

//         <div className="col-span-6 sm:flex sm:items-center gap-4">
//           <button
//             type="submit"
//             className="inline-block w-full border border-white bg-black py-3 md:py-4 lg:px-12 lg:py-4 text-xs md:text-sm font-thin text-white transition hover:bg-white hover:border-black hover:text-black focus:outline-none tracking-[0.2rem]"
//           >
//             REGISTER YOUR INTEREST
//           </button>
//         </div>
//       </form>

//       <div className="w-full text-center font-thin mt-1">
//         <p
//           className={`text-xs md:text-sm ${
//             theme === "light" ? "text-black" : "text-gray-400"
//           }`}
//         >
//           By clicking Submit, you agree to our{" "}
//           <a
//             href="https://www.xrealty.ae/privacy-policy/"
//             className={`underline font-bold ${
//               theme === "light" ? "text-black" : "text-gray-400"
//             }`}
//           >
//             Terms and Conditions
//           </a>{" "}
//           and{" "}
//           <a
//             href="https://www.xrealty.ae/privacy-policy/"
//             className={`underline font-bold ${
//               theme === "light" ? "text-black" : "text-gray-400"
//             }`}
//           >
//             Privacy Policy
//           </a>
//           .
//         </p>
//       </div>
//     </div>
//   );
// }

// export default LetXpertsInput;

// import React, { useState } from "react";
// import axios from "axios";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { CONTACT_US } from "../api/constants";
// import { useLocation } from "react-router-dom";

// function LetXpertsInput({ theme, onFormSubmit }) {
//   const { pathname } = useLocation();

//   const initialFormData = {
//     firstname: "",
//     lastname: "",
//     email: "",
//     phone: "",
//     pageUrl: "",
//   };

//     console.log(theme);

//   const [formData, setFormData] = useState(initialFormData);

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.id]: e.target.value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       formData.pageUrl = `https://www.xrealty.ae${pathname}`;
//       const response = await axios.post(CONTACT_US, formData)

//       // Handle success based on status code
//       if (response.status === 201) {
//         toast.success(response.data.message || "Form submitted successfully!");
//         setFormData(initialFormData);
//         localStorage.setItem("formSubmitted", "true");
//       }
//     } catch (error) {

//       // Check if the error is due to validation or server issues
//       if (error.response && error.response.status === 400) {
//         toast.error(error.response.data.error || "Validation error. Please check your input.");
//       } else {
//         toast.error("An error occurred during form submission.");
//       }
//     }
//   };

//   return (
//     <>
//         <h1
//           className={` text-base md:text-xl text-center mb-4 ${
//             theme === "light" ? " text-black" : "text-white"
//           }`}
//         >
//           CONNECT WITH OUR EXPERTS
//         </h1>
//       <div className="flex flex-col justify-center items-center w-full lg:gap-6 py-12 md:py-14 lg:py-0">
//         <form
//           onSubmit={handleSubmit}
//           className="w-full grid grid-cols-6 gap-6 pt-5 lg:pt-0"
//         >
//           <div className="col-span-6 sm:col-span-3 ">
//             <input
//               type="text"
//               id="firstname"
//               name="first_name"
//               placeholder="First Name"
//               className={`font-thin mt-1 w-full py-3 text-xs   md:text-sm  shadow-sm border-b focus:outline-none ${
//                 theme === "light"
//                   ? "bg-white border-black text-black"
//                   : "bg-black text-gray-400 border-gray-400"
//               }`}
//               value={formData.firstname}
//               onChange={handleChange}
//             />
//           </div>

//           <div className="col-span-6 sm:col-span-3">
//             <input
//               type="text"
//               id="lastname"
//               name="last_name"
//               placeholder="Last Name"
//               className={`font-thin mt-1 w-full py-3 text-xs  md:text-sm  shadow-sm border-b focus:outline-none ${
//                 theme === "light"
//                   ? "bg-white border-black text-black"
//                   : "bg-black text-gray-400 border-gray-400"
//               }`}
//               value={formData.lastname}
//               onChange={handleChange}
//             />
//           </div>

//           <div className="col-span-6">
//             <input
//               type="email"
//               id="email"
//               name="email"
//               placeholder="Email Address"
//               className={`font-thin mt-1 w-full py-3 text-xs  md:text-sm   shadow-sm border-b focus:outline-none ${
//                 theme === "light"
//                   ? "bg-white border-black text-black"
//                   : "bg-black text-gray-400 border-gray-400"
//               }`}
//               value={formData.email}
//               onChange={handleChange}
//             />
//           </div>

//           <div className="col-span-6 sm:col-span-6">
//             <input
//               type="tel"
//               id="phone"
//               name="phone"
//               placeholder="Phone Number"
//               className={`font-thin mt-1  w-full py-3 text-xs md:text-sm  border-b focus:outline-none ${
//                 theme === "light"
//                   ? "bg-white border-black text-black"
//                   : "bg-black text-gray-400 border-gray-400"
//               }`}
//               value={formData.phone}
//               onChange={handleChange}
//             />
//           </div>

//           <div className="col-span-6 sm:flex sm:items-center gap-4">
//             <button
//               type="submit"
//               className="inline-block shrink-0 w-full border border-white bg-black py-3 md:py-4 lg:px-12 lg:py-4 text-xs md:text-sm  font-thin text-white transition hover:border hover:bg-white  hover:border-black  focus:outline-none hover:text-black tracking-[0.2rem]"
//             >
//               REGISTER YOUR INTEREST
//             </button>
//           </div>
//         </form>
//         <div className="w-full text-center font-thin">
//           <p
//             className={`text-xs  md:text-sm   shadow-sm ${
//               theme === "light" ? " text-black" : " text-gray-400"
//             }`}
//           >
//             By clicking Submit, you agree to our{" "}
//             <a
//               href="https://www.xrealty.ae/privacy-policy/"
//               className={`underline font-bold ${
//                 theme === "light" ? " text-black" : " text-gray-400"
//               } `}
//             >
//               Terms and Conditions
//             </a>{" "}
//             and{" "}
//             <a
//               href="https://www.xrealty.ae/privacy-policy/"
//               className={`underline font-bold ${
//                 theme === "light" ? " text-black" : " text-gray-400"
//               } `}
//             >
//               Privacy Policy
//             </a>
//             .
//           </p>
//         </div>
//         {/* <ToastContainer /> */}
//       </div>
//     </>
//   );
// }

// export default LetXpertsInput;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CONTACT_US } from "../api/constants";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

function LetXpertsInput({ theme, onFormSubmit, registerInterest, property }) {
  const { pathname } = useLocation();

  const initialFormData = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    pageUrl: "",
    ipAddress: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  async function getUserIP() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // formData.pageUrl = `https://www.xrealty.ae${pathname}`;
      formData.pageUrl = window.location.href;
      formData.ipAddress = await getUserIP();
      const response = await axios.post(CONTACT_US, formData);

      if (response.status === 201) {
        // toast.success(response.data.message || "Form submitted successfully!");
        setFormData(initialFormData);

        // Show confirmation popup
        setIsSubmitted(true);

        // Hide the popup after 3 seconds
        setTimeout(() => {
          setIsSubmitted(false);
        }, 3000);

        localStorage.setItem("formSubmitted", "true");

        // Push custom event to dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Website Lead form submit",
        });
        ReactPixel.trackCustom("Website Lead form submit", {
          // Optional parameters
          page: pathname,
        });
        if (onFormSubmit) onFormSubmit();
      }
    } catch (error) {
      const errorMessage =
        error.response?.status === 400
          ? error.response.data.error ||
            "Validation error. Please check your input."
          : "An error occurred during form submission.";
      toast.error(errorMessage);
    }
  };
  return (
    <div
      className={`flex flex-col items-center w-full ${
        registerInterest === true
          ? "py-10 md:py-14 lg:py-4"
          : "py-4 md:py-14 lg:py-0"
      }`}
    >
      <h2
        className={`text-base md:text-xl text-center mb-4 ${
          theme === "light" ? "text-black" : "text-white"
        } ${property ? "hidden" : "block"}`}
      >
        CONNECT WITH OUR XPERTS
      </h2>
      <form
        onSubmit={handleSubmit}
        className="w-full grid grid-cols-6 gap-6 pt-5 lg:pt-0"
      >
        <div className="col-span-6 sm:col-span-3">
          <input
            type="text"
            id="firstname"
            name="firstname"
            placeholder="First Name"
            className={`font-thin mt-1 w-full py-3 text-xs md:text-sm shadow-sm border-b focus:outline-none ${
              theme === "light"
                ? "bg-gray-100 border-black text-black"
                : "bg-black text-gray-400 border-gray-400"
            }`}
            value={formData.firstname}
            onChange={handleChange}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <input
            type="text"
            id="lastname"
            name="lastname"
            placeholder="Last Name"
            className={`font-thin mt-1 w-full py-3 text-xs md:text-sm shadow-sm border-b focus:outline-none ${
              theme === "light"
                ? "bg-gray-100 border-black text-black"
                : "bg-black text-gray-400 border-gray-400"
            }`}
            value={formData.lastname}
            onChange={handleChange}
          />
        </div>

        <div className="col-span-6">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email Address"
            className={`font-thin mt-1 w-full py-3 text-xs md:text-sm shadow-sm border-b focus:outline-none ${
              theme === "light"
                ? "bg-gray-100 border-black text-black"
                : "bg-black text-gray-400 border-gray-400"
            }`}
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className="col-span-6">
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Phone Number"
            className={`font-thin mt-1 w-full py-3 text-xs md:text-sm border-b focus:outline-none ${
              theme === "light"
                ? "bg-gray-100 border-black text-black"
                : "bg-black text-gray-400 border-gray-400"
            }`}
            value={formData.phone}
            onChange={handleChange}
          />
        </div>

        <div className="col-span-6 sm:flex sm:items-center gap-4">
          <button
            type="submit"
            className="inline-block w-full border border-white bg-black py-3 md:py-4 lg:px-12 lg:py-4 text-xs md:text-sm font-thin text-white transition hover:bg-white hover:border-black hover:text-black focus:outline-none tracking-[0.2rem]"
          >
            REGISTER YOUR INTEREST
          </button>
        </div>
      </form>

      <div className="w-full text-center font-thin mt-1">
        <p
          className={`text-xs md:text-sm ${
            theme === "light" ? "text-black" : "text-gray-400"
          }`}
        >
          By clicking Submit, you agree to our{" "}
          <a
            href="https://www.xrealty.ae/privacy-policy/"
            className={`underline font-bold ${
              theme === "light" ? "text-black" : "text-gray-400"
            }`}
          >
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a
            href="https://www.xrealty.ae/privacy-policy/"
            className={`underline font-bold ${
              theme === "light" ? "text-black" : "text-gray-400"
            }`}
          >
            Privacy Policy
          </a>
          .
        </p>
      </div>

      {isSubmitted && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80 text-center">
            <h3 className="text-xl font-medium text-gray-800 mb-2">
              Form Submitted!
            </h3>
            <p className="text-gray-600">Thank you for submitting the form.</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default LetXpertsInput;
