import React, { useEffect, useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./StarEmployeesImages.css"; // Import your custom CSS
import axios from "axios";
import { STAR_AGENTS } from "../api/constants";

function StarEmployeesImages({ data }) {
  const [starAgent, setStarAgent] = useState();

  useEffect(() => {
    // const fetchAgents = async (page, limit) => {
    //   try {
    //     const response = await axios.get(STAR_AGENTS);
    //     setStarAgent(response?.data?.agents);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };
    // fetchAgents();
    setStarAgent(data);
  }, [data]);

  const settings = {
    infinite: true,
    speed: 1500,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="md:mt-2">
      <Slider {...settings}>
        {starAgent?.map((imageWithInfo, index) => (
          <div
            key={index}
            className="h-auto flex shadow-sm shadow-black bg-black text-white "
          >
            <Link
              reloadDocument
              to={`/agent/${imageWithInfo?.name_slug}/`}
              className="block overflow-hidden shadow-2xl relative"
            >
              <img
                src={imageWithInfo?.profile_picture}
                alt={imageWithInfo?.name}
                className="object-cover transition-transform duration-500 group-hover:scale-110"
              />
            </Link>
            <div className="flex justify-between items-center p-5 overflow-auto">
              <dl className="justify-center flex flex-col w-2/3">
                <div>
                  <dt className="sr-only">Name</dt>
                  <dd className="text-xs md:text-xs xl:text-sm  2xl:text-xl text-ellipsis overflow-clip line-clamp-1">
                    {imageWithInfo?.name}
                  </dd>
                </div>
                <div>
                  <dt className="sr-only">Languages</dt>
                  <dd className="text-xs md:text-xs xl:text-sm  2xl:text-lg text-ellipsis overflow-clip line-clamp-1">
                    {imageWithInfo?.languages?.join(", ")}
                  </dd>
                </div>
              </dl>
              <dl>
                <a
                  href={`tel:${imageWithInfo?.phone}`}
                  className="border border-white w-8 h-8 2xl:w-12 2xl:h-12 p-2 flex justify-center items-center"
                >
                  <FaPhoneAlt />
                </a>
              </dl>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default StarEmployeesImages;

// import React, { useRef } from "react";
// import { FaPhoneAlt } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// function StarEmployeesImages({ imagesWithInfo }) {
//   const sliderRef = useRef(null);

//   const settings = {
//     infinite: true,
//     speed: 3000,
//     autoplay: true, // Disable autoplay for manual control
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     draggable: false,
//     swipe: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 640,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   const nextSlide = () => {
//     sliderRef.current.slickNext();
//   };

//   const prevSlide = () => {
//     sliderRef.current.slickPrev();
//   };

//   return (
//     <div className="relative">
//       <button
//         onClick={prevSlide}
//         className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white text-black p-2"
//         style={{ zIndex: 2 }}
//       >
//         Prev
//       </button>
//       <Slider ref={sliderRef} {...settings}>
//         {imagesWithInfo?.map((imageWithInfo, index) => (
//           <div
//             key={index}
//             className="h-auto flex shadow-sm shadow-black bg-black text-white border "
//           >
//             <Link
//               to={`/agent/${imageWithInfo?.name_slug}`}
//               className="block overflow-hidden shadow-2xl relative"
//             >
//               <img
//                 src={imageWithInfo?.profile_picture}
//                 alt={imageWithInfo?.name}
//                 className="w-full h-[50vh] object-cover object-top transition-transform duration-500 group-hover:scale-110"
//               />
//             </Link>
//             <div className="flex justify-between items-center p-5 overflow-auto ">
//               <dl className="h-24 justify-center flex flex-col w-2/3 ">
//                 <div>
//                   <dt className="sr-only">Name</dt>
//                   <dd className="text-2xl lg:text-lg xl:text-xl 2xl:text-2xl text-ellipsis overflow-clip">
//                     {imageWithInfo?.name}
//                   </dd>
//                 </div>
//                 <div>
//                   <dt className="sr-only">Languages</dt>
//                   <dd className="text-xl lg:text-base xl:text-lg 2xl:text-xl text-ellipsis overflow-clip">
//                     {imageWithInfo?.languages?.join(", ")}
//                   </dd>
//                 </div>
//               </dl>
//               <dl>
//                 <a
//                   href={`tel:${imageWithInfo?.phone}`}
//                   className="border border-white w-12 h-12 p-2 flex justify-center items-center"
//                 >
//                   <FaPhoneAlt />
//                 </a>
//               </dl>
//             </div>
//           </div>
//         ))}
//       </Slider>
//       <button
//         onClick={nextSlide}
//         className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white text-black p-2"
//         style={{ zIndex: 2 }}
//       >
//         Next
//       </button>
//     </div>
//   );
// }

// export default StarEmployeesImages;
