import React from "react";
import { IoPersonOutline } from "react-icons/io5";
import { RiStarSFill } from "react-icons/ri";

function FullReviewModal({ isOpen, onClose, review }) {
  if (!isOpen || !review) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
      <div className="bg-white text-black p-5 lg:p-10 w-full  max-w-3xl mx-6 lg:mx-4  shadow-2xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-base lg:text-lg font-medium">
            {review?.name?.toUpperCase()}
          </h2>
          <button
            className="text-black text-2xl"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <div className="flex items-center gap-4 mb-6">
          {review.imageUrl ? (
            <img
              src={review?.imageUrl}
              alt="Profile"
              className="w-12  h-12 lg:w-16 lg:h-16 object-cover "
            />
          ) : (
            <IoPersonOutline className="w-24 h-24 border-2 border-black p-1 " />
          )}
          <div className="flex justify-center gap-1">
            {[...Array(review?.numberOfStars)].map((_, i) => (
              <RiStarSFill key={i} className="text-black w-6 h-6 text-xl" />
            ))}
          </div>
        </div>
        <p className="text-gray-700 text-base lg:text-lg ">
          {review?.message}
        </p>
      </div>
    </div>
  );
}

export default FullReviewModal;
