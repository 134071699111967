import React from "react";

const UploadReviewImages = ({ onImagesChange }) => {
  const handleUploadClick = (event) => {
    event.preventDefault(); // Prevent form submission

    // Ensure cloudinary object is available
    if (window.cloudinary) {
      window.cloudinary.openUploadWidget(
        {
          cloudName: "dkhns25jh", // Replace with your Cloudinary cloud name
          uploadPreset: "ml_default", // Replace with your Cloudinary upload preset
          folder: "xr_media", // Optional: Change to your desired folder
        },
        function (error, result) {
          if (!error && result && result.event === "success") {
            const uploadedImage = {
              url: result.info.secure_url,
            };
            onImagesChange([uploadedImage]); // Update parent component's state with a single image
          }
        }
      );
    } else {
      console.error("Cloudinary widget script is not loaded.");
    }
  };

  return (
    <button
      className="text-sm lg:text-lg  inline-flex w-full items-center justify-center border border-black px-10 py-2 text-center font-medium text-black hover:bg-black hover:bg-opacity-90 hover:text-white lg:px-8 xl:px-6"
      onClick={handleUploadClick}
    >
      Upload Image
    </button>
  );
};

export default UploadReviewImages;
