// components/Shimmer.js
import React from "react";
import "./Shimmer.css";

const Shimmer = () => {
  return (
    <div className="shimmer-wrapper">
      <div className="shimmer"></div>
    </div>
  );
};

export default Shimmer;
