import React, { useEffect, useState, useRef } from "react";

const ScrollTriggerModal = ({ triggerPoints, children }) => {
  const [showModal, setShowModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(
    localStorage.getItem("formSubmitted") === "true"
  );
  const [modalDisabled, setModalDisabled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const lastScrollPositionRef = useRef(window.scrollY); // Use useRef to store the last scroll position

  // Constants for expiration
  const FORM_EXPIRATION_DAYS = 1; // or 2 for two days
  const FORM_EXPIRATION_MS = FORM_EXPIRATION_DAYS * 24 * 60 * 60 * 1000; // Convert days to milliseconds
  const MODAL_TIMEOUT_MS = 1 * 60 * 1000; // 4 minutes modal timeout

  useEffect(() => {
    // Check if the formSubmitted state has expired
    const formSubmittedTimestamp = localStorage.getItem(
      "formSubmittedTimestamp"
    );
    if (formSubmittedTimestamp) {
      const timeElapsed = Date.now() - parseInt(formSubmittedTimestamp, 10);
      if (timeElapsed > FORM_EXPIRATION_MS) {
        // Reset formSubmitted if expired
        setFormSubmitted(false);
        localStorage.removeItem("formSubmitted");
        localStorage.removeItem("formSubmittedTimestamp");
      }
    }
  }, []);

  useEffect(() => {
    // Reset `triggered_onPage` when the component mounts if the modal was previously closed and the timeout has passed
    const modalClosedTimestamp = localStorage.getItem("modalClosedTimestamp");
    const timeElapsed = modalClosedTimestamp
      ? Date.now() - parseInt(modalClosedTimestamp, 10)
      : Infinity;

    if (timeElapsed > MODAL_TIMEOUT_MS) {
      localStorage.removeItem("triggered_onPage"); // Reset modal trigger
    }

    if (formSubmitted || modalDisabled) return;

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight;
      const lastScrollPosition = lastScrollPositionRef.current; // Get the last scroll position

      const isScrollingDown = scrollPosition > lastScrollPosition; // Corrected: Scrolling down when current position is greater than last

      triggerPoints.forEach((point) => {
        const triggerHeight = pageHeight * point;

        if (
          scrollPosition >= triggerHeight &&
          isScrollingDown && // check if scrolling down
          !localStorage.getItem("triggered_onPage")
        ) {
          setShowModal(true);
          localStorage.setItem("triggered_onPage", true); // Set the modal as triggered
        }
      });

      // Update the last scroll position **after** handling scroll logic
      lastScrollPositionRef.current = scrollPosition;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [triggerPoints, formSubmitted, modalDisabled]);

  const closeModal = () => {
    setShowModal(false);

    // Disable modal for 4-5 minutes
    setModalDisabled(true);
    const timestamp = Date.now();

    localStorage.setItem("modalClosedTimestamp", timestamp.toString()); // Store the timestamp when modal was closed

    setTimeout(() => {
      // Reset the trigger points after timeout
      setModalDisabled(false);
      localStorage.removeItem("triggered_onPage"); // Allow modal to trigger again after timeout
    }, MODAL_TIMEOUT_MS); // 4 minutes timeout
  };

  const handleFormSubmit = () => {
    setFormSubmitted(true);
    // Show confirmation popup
    setIsSubmitted(true);

    // Hide the popup after 3 seconds
    setTimeout(() => {
      setIsSubmitted(false);
    }, 3000);
    const timestamp = Date.now();
    localStorage.setItem("formSubmitted", "true");
    localStorage.setItem("formSubmittedTimestamp", timestamp.toString());
  };

  return (
    <>
      {!formSubmitted && showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded shadow-lg relative">
            <button
              className="absolute top-0 right-2 text-gray-700 text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>
            {/* Clone the child component and pass the onFormSubmit handler */}
            {React.cloneElement(children, { onFormSubmit: handleFormSubmit })}
          </div>
        </div>
      )}

      {isSubmitted && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80 text-center">
            <h3 className="text-xl font-medium text-gray-800 mb-2">
              Form Submitted!
            </h3>
            <p className="text-gray-600">Thank you for submitting the form.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ScrollTriggerModal;
