import React, { useState } from "react";
import UploadReviewImages from "./UploadReviewImages";
import { FaStar } from "react-icons/fa";
import { REVIEWS } from "../api/constants";
import axios from "axios";
import { RiStarSFill } from "react-icons/ri";

function ReviewPopup({ isOpen, onClose, onSuccess, onError }) {
  const initialFormData = {
    name: "",
    message: "",
    imageUrl: "",
    numberOfStars: 5,
    showReview: false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleImagesChange = (updatedImages) => {
    const imageUrl = updatedImages.length > 0 ? updatedImages[0].url : "";
    setFormData({
      ...formData,
      imageUrl,
    });
  };

  const handleRemoveImage = () => {
    setFormData({
      ...formData,
      imageUrl: "", // Clear the image URL
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(REVIEWS, formData);
      if (response.status === 201) {
        onSuccess("Form submitted successfully!");
        setFormData(initialFormData); // Reset form after submission
        // Push custom event to dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Website Review form submit",
        });
        onClose(); // Close the popup after submission
      } else {
        onError("An error occurred during form submission.");
      }
    } catch (error) {
      if (error.response && error.response.status !== 201) {
        onError(
          error.response?.data?.error ||
            "An error occurred during form submission."
        );
      } else {
        onError("An unknown error occurred.");
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 ">
      <div className="bg-white text-black p-8 lg:p-10 w-full max-w-lg border-t-8 border-black">
        <h2 className="text-2xl  font-semibold mb-2 text-center ">
          Leave a Review
        </h2>

        <div className="flex justify-center mb-8">
          {[...Array(5)].map((_, i) => (
            <RiStarSFill
              key={i}
              className={`cursor-pointer text-3xl ${
                i < formData.numberOfStars ? "text-black" : "text-gray-300"
              }`}
              onClick={() => setFormData({ ...formData, numberOfStars: i + 1 })}
            />
          ))}
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-8">
            <label
              className="block text-sm lg:text-lg font-medium "
              htmlFor="name"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              className="w-full px-4  border-b-2 border-gray-300 focus:border-black focus:outline-none text-lg"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div className="mb-8">
            <label
              className="block text-sm lg:text-lg  font-medium"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              id="message"
              className="w-full px-4  border-b-2 border-gray-300 focus:border-black focus:outline-none text-lg"
              rows="1"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>

          <div className="mb-8">
            <label className="block text-sm lg:text-lg  font-medium mb-3">
              Image
            </label>
            {formData.imageUrl ? (
              <div className="relative">
                <img
                  src={formData.imageUrl}
                  alt="Uploaded"
                  className="mb-2 h-20 w-20 object-cover"
                />
                <button
                  type="button"
                  onClick={handleRemoveImage}
                  className="absolute right-0 top-0 text-2xl text-black"
                >
                  &times;
                </button>
              </div>
            ) : (
              <UploadReviewImages onImagesChange={handleImagesChange} />
            )}
          </div>

          <div className="flex justify-between items-center">
            <button
              type="button"
              className="bg-white text-black px-6 py-2 uppercase tracking-wide hover:bg-black hover:text-white border border-black transition-colors duration-300 text-sm lg:text-lg "
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-black text-white px-6 py-2 uppercase tracking-wide hover:bg-white hover:text-black border border-black transition-colors duration-300 text-sm lg:text-lg "
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ReviewPopup;
